.promo-video {
    width: 70%;
    display: block;
    margin: 0 auto;
    padding: 0.5rem;
}

.row ul  {
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
}

.info {
    padding: 1.5rem;
}

.contact_link a {
    color: black;
}

.contact_link a:hover {
    color: rgb(11, 95, 116);
}

.heading {
    font-size: 40px;
    padding: 2px 2px 0.5px;
    margin: 20px;
    text-align: center;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .promo-video {
        width: 80%;
        height: 55vh;
        display: block;
        margin: 0 auto;
        
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .promo-video {
        width: 85%;
        height: 40vh;
        display: block;
        margin: 0 auto;
        padding: 10;
    }
}
