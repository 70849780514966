@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

a:link {
    text-decoration: none;
}

/* css variables */
:root {
    --color-bg: white;
    --color-bg-light: white; 
    --color-bg-variant: rgb(20, 111, 141);
    --color-primary: black;
    --color-primary-variant: rgb(20, 111, 141);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

body {
    font-family: 'Lato', sans-serif;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    line-height: 40px;
}

.main {
    flex-grow: 1;
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

.primary_button {
    background: var(--color-primary-variant);
}

.primary_button:hover {
    background: var(--color-bg-variant);
    transition: var(--transition);
}

.primary_button:active {
    background: var(--color-bg-variant);
    transition: var(--transition);
}

.floating-btn, .floating-btn:hover {
    width: 80px;
    height: 80px;
    background: #4589a8;
    display: flex; 
    border-radius: 50%;
    color: white;
    font-size: 40px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
    position: fixed;
    right: 20px;
    bottom: 100px;
    transition: background 0.25s;

    /* centered horizontally and vertically */
    align-items: center;
    justify-content: center;

    /* button */
    border: none;
    cursor: pointer;
}

.floating-btn:active {
    background: #55a8cf;
    
}


/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

}


/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
        
    }
}

.row-test {
    background-color: #0e2c46;
    /* background-image: linear-gradient(rgb(30, 145, 183), rgb(6, 48, 62)); */
    padding: 2px;
    margin-bottom: 8px;
}

.tagline {
    font-size: 26px;
    color: #4589a8;
    padding: 5px;
    margin: 0.5rem;
}

.main-heading {
    color: #0e2c46;
    font-size: 40px;
    padding-bottom: 5px;
}


p {
    margin: 15px 0px;
}

.bio {
    color: white;
}

/* h2.slide-text {
    color: red;
} */