.carousel {
    width: 70vw;
    height: 70vh;
    margin: 5vh auto;
}

.carousel-caption p {
    font-size: 35px;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .carousel {
        width: 80%;
        height: 100vh;
        margin: 2vh auto;
        margin-bottom: 20vh;
    }

    .carousel-caption p {
        font-size: 1rem;
        line-height: 2.5rem;
    }
    
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .carousel {
        width: 90vw;
        height: 30vh;
        margin: 2vh auto;
        margin-bottom: 13vh;
    }
    
    .carousel-caption p {
        font-size: 0.6rem;
        line-height: 11.5px;
    }
    
}


